import React from "react"
import Layout from "../components/layout";
import Promo from "../components/promo";
import Feature from "../components/feature";
import Title from "../components/title";
import { Helmet } from 'react-helmet';
import { Link } from "gatsby";

import Targets from "../../static/icons/targets.inline.svg";
import Holiday from "../../static/icons/holiday.inline.svg";
import Forecast from "../../static/icons/forecast.inline.svg";
import estimates from "../../static/product/estimates.svg";
import Arrow from "../../static/icons/arrow.inline.svg";

const Product = ({ location }) => (

    <Layout 
      path={location.pathname}>

      <Helmet
        bodyAttributes={{
          class: 'bg-gray-100 text-root'
        }} />

      <Title
        colour="teal-500"
        title="Forecast your finances"
        width="max-w-xs xl:max-w-xl">

        <p className="max-w-xl leading-relaxed mx-auto my-8 text-dark text-center text-lg md:text-xl">
        Get an instant snapshot of your finances. See how you're doing compared with the financial goals you've set.</p>

      </Title>

      <div className="max-w-8xl xl:px-12 mx-auto mb-16 md:mb-20">

        <div className="relative pb-16/9 rounded-lg overflow-hidden">
          <video className="outline-none absolute w-full h-full object-cover" autoPlay muted loop playsinline>
            <source src="/videos/feature_forecast.mp4" type="video/mp4" />
          </video>
        </div>

      </div>

      <div className="lg:container max-w-6xl mx-auto md:text-lg">

        <div className="lg:flex lg:space-x-8">

          <div className="lg:w-1/2 space-y-4 sm:space-y-8">

            <Feature 
              icon={<Forecast />}
              title="A peak into the future."
              text="Slate predicts how much you're on track to earn by the end of year, giving you the knowledge to decide what 
              to spend your time on for the rest of the year, whether that's client work or side projects."
              colour="teal-500" />

            <Feature 
              icon={<Targets />}
              title="Revenue tracking."
              text="Some months you'll earn more than others, that's just how freelancing is. Slate makes it easy to see 
              what you need to earn in the future so you don't miss your financial target for the year."
              colour="teal-500" />

          </div>

          <div className="lg:w-1/2 space-y-4 sm:space-y-8">

            <Feature 
              icon={<Holiday />}
              title="Time off without the worry."
              text="Slate gives you a real-time view of how much you've worked, and how much more you need to work to meet your 
              financial target. Knowing if you can afford to book time off has never been easier."
              colour="teal-500" />

            <Feature 
              icon={<Targets />}
              title="Revenue tracking."
              text="Some months you'll earn more than others, that's just how freelancing is. Slate makes it easy to see 
              what you need to earn in the future so you don't miss your financial target for the year."
              colour="teal-500" />

          </div>

        </div>

      </div>

      <Promo theme="dark" />

    </Layout>
)

export default Product;